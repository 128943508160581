
import React, { useEffect } from 'react';

const Sitemap = () => {
  useEffect(() => {
    const sitemapXML =`<?xml version="1.0" encoding="UTF-8"?>
<urlset
      xmlns="http://www.sitemaps.org/schemas/sitemap/0.9"
      xmlns:xsi="http://www.w3.org/2001/XMLSchema-instance"
      xsi:schemaLocation="http://www.sitemaps.org/schemas/sitemap/0.9
            http://www.sitemaps.org/schemas/sitemap/0.9/sitemap.xsd">
<!-- created with Free Online Sitemap Generator www.xml-sitemaps.com -->


<url>
  <loc>https://www.placestovisitindia.com/</loc>
  <lastmod>2024-12-14T05:45:43+00:00</lastmod>
</url>


</urlset>`;

    

    const blob = new Blob([sitemapXML], { type: 'application/xml' });
    const url = URL.createObjectURL(blob);
    window.location.href = url;

    return () => URL.revokeObjectURL(url);
  }, []);

  return null; 
};

export default Sitemap;
