import React from 'react';
import './Loader.css';
import lookit from "../Component/Image/lookit.png"

const Loader = ({ loader }) => { // Destructure 'loader' from props
    return (
        <>
            {loader ? (
                <div className="loader-overlay">
                    <div className="spinner"></div>
                    <img src={lookit} alt="Loading" className="loader-image" />
                </div>
            ) : null}
        </>
    );
};

export default Loader;
